import styled from 'styled-components';
import { font } from '../../atoms/typography';

export const Card = styled.div`
  margin-bottom: 30px;

  @media (max-width: 993px) {
    padding: 20px;
  }

  a {
    &:hover {
      text-decoration: unset;
    }
  }

  h4 {
    ${font('text', 'lg', '700')}
    color: #171E2A;
    margin-bottom: 8px;
    padding-left: 8px;
  }
  
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 8px;
    list-style: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    ${font('text', 'md', '400')}
    color: #2c3641;
    margin-bottom: 0;
    border-left: 2px solid transparent;
    padding-left: 8px;
    cursor: pointer;

    &:hover {
      font-weight: 700 !important;
      color: #171e2a;

      * {
        font-weight: 700 !important;
      }
    }
  }
  
  a.active {
    p {
      font-weight: 700 !important;
      color: #171e2a;
      border-color: #1c3fff;

      * {
        font-weight: 700 !important;
      }
    }
  }

  .active-tag {
    font-weight: 700 !important;
    color: #171e2a;
    border-color: #1c3fff;

    * {
      font-weight: 700 !important;
    }
  }
`;
